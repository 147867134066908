import axios from "axios";
import Cookies from "js-cookie";
import { authenticationCode } from "./API";
import { URL_API } from "./constant";
import { ACCESS_TOKEN_KEY } from "./token";
import { clearLocalStorage } from "../core/ultis/ultis"

const axiosInstance = axios.create({
    baseURL: URL_API,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': Cookies.get(ACCESS_TOKEN_KEY)
    }
});

axiosInstance.interceptors.request.use(async (req) => {
    const accessToken = Cookies.get(ACCESS_TOKEN_KEY)
    if (accessToken) {
        req.headers.Authorization = `Bearer ${accessToken}`;
    }
    return req;

}
)
axiosInstance.interceptors.response.use((res) => {
    return res
}, err => {
    if (err.response && err.response.status === authenticationCode) {
        Cookies.remove();
        clearLocalStorage();
        if (
            window.location.pathname === '/' ||
            window.location.pathname === '/register' ||
            window.location.pathname === '/forgot'
        ) {
            return;
        }
        window.location.pathname = '/'
    }
    return Promise.reject(err);
}
);
export default axiosInstance;