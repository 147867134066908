import {createAsyncThunk} from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import {ACCESS_TOKEN_KEY} from "../config/token";
import {_convertObjectToQuery} from "./winer-service";
import axiosInstance from "../config/customAxios";

const token = Cookies.get(ACCESS_TOKEN_KEY)
 
export const showListNews = createAsyncThunk(
    'news/showListNews',
    async (body) => {
          let res = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/api/app/news/new-by-category?${_convertObjectToQuery(body)}`, {
        })
 
        return res.data
    }
)


 
export const detailNews = createAsyncThunk(
    'news/detailNews',
    async (id) => {
        try{
            let res = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/api/app/news/${id}/async-in-web`);            
            return res.data
        } catch (e){
            
        }

    }
)