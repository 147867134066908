import {createAsyncThunk} from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import {ACCESS_TOKEN_KEY} from "../config/token";
import axiosInstance from "../config/customAxios";

export const _convertObjectToQuery = (obj) => {
    let query = '';
    for (let key in obj) {
        if (obj[key] !== undefined) {
            if (query) {
                query += `&${key}=${obj[key]}`;
            } else {
                query += `${key}=${obj[key]}`;
            }
        }
    }
    return query;
}
const token = Cookies.get(ACCESS_TOKEN_KEY)

export const showListWiner = createAsyncThunk(
    'winer/showListWiner',
    async (body) => {
        let res = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/api/app/winer/async-by-user?${_convertObjectToQuery(body)}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return res.data
    }
)

export const download =  createAsyncThunk(
    'auction-c08/blob-download',
    async (id) => {
        let res = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/api/app/winer/${id}/download-file`, {
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token}`
                }
        })
        return res.data
    }
)

 
export const downloadFile = createAsyncThunk(
    'auction-c08/blob-downloadfile',
    async (File) => {
        let res = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/api/app/auction-c08/blob-download?link=${File}`, {
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token}`
                }
        })
        return res.data
    }
)

 
export const downloadQD = createAsyncThunk(
    'auction-c08/blob-downloadQD',
    async (id) => {
        console.log(id)
        let res = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/api/app/auction-c08/file-winner-auction2/${id}`, {
            responseType: 'blob',
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return res.data
    }
)


export const downloadHD = createAsyncThunk(
    'auction-c08/blob-downloadHD',
    async (id) => {
        let res = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/api/app/invoices-vnpt/file-pdf-for-user?fkey=${id}`)
        return res.data
    }
)
// export const downloadHD = createAsyncThunk(
//     'auction-c08/blob-downloadHD',
//     async (id) => {
//         let res = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/api/app/invoices/get-file-pdf`,[{
//             partnerInvoiceID: id,
//             partnerInvoiceStringID: ''
//         }])
//         return res.data
//     }
// )