import {createAsyncThunk} from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import {ACCESS_TOKEN_KEY} from "../config/token";
import {_convertObjectToQuery} from "./winer-service";
import axiosInstance from "../config/customAxios";

const token = Cookies.get(ACCESS_TOKEN_KEY)
export const registerAPI = createAsyncThunk(
    'register-extend/register',
    // async (body) => {
    //     let res = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/api/app/auction-c08/upload-extend-no-place`, {
    //         headers: {
    //             Authorization: `Bearer ${token}`
    //         },
    //         body
    //     })
     
    //     return res.data
    // }
    async (data) => {
     
        const res = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/api/app/auction-c08/upload-extend-no-place`, data, 
            {
                headers: {
                "Content-Type": "multipart/form-data"
                }
            }
        )
        return res.data
   }

)

 