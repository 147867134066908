import React from "react";
import {useEffect, useState} from "react";
import { Field, Form, Formik } from "formik";
import {registerAPI} from "../../service/register-extend";
import { Button } from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import './index.scss'
import {toast} from "react-toastify";
import {toastCss} from "../../config/toasterConfig";  
import {fetchUserProfile} from "../../service/user-service";
import * as Yup from 'yup';

import { saveAs } from "file-saver";
import { Packer } from "docx";
import { DocumentCreator } from "./cv-generator";
import {showListWiner} from "../../service/winer-service";
import Sidebar from "../../components/Sidebar";
import { useLocation } from "react-router-dom";

 


function RegisterExtend() {
    const [pageCurrent, setPageCurrent] = useState('');
    const location = useLocation();
    useEffect(() => {
        let lastPart = location.pathname.split("/").pop();;
        setPageCurrent(lastPart);
    }, [location.pathname]);


    // Initially, no file is selected
    const [selectedFile, setSelectedFile] = useState();
	const [isFilePicked, setIsFilePicked] = useState(false);
    const [fileVal,setFileVal] = useState();
 
    const dispatch = useDispatch();
    const changeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
		setIsFilePicked(true);
 	};
    const handleSubmit = async (noPlate) => {
        const dataBody = convertDataSubmit( noPlate);
        
       
        if(!dataBody.File){
            toast.error('Đơn đề nghị không được để trống', toastCss)
            return;
        }else{
            const ext = dataBody.File.name.split(".").pop();
            if(ext === 'doc' || ext === 'docx' || ext === 'pdf' || ext === 'png' || ext === 'jpg' || ext === 'jpeg'){
                dispatch(registerAPI(dataBody)).then((res) => {
                    // console.log(res);
                    if(res.error){
                            toast.error('Lỗi đề nghị gia hạn', toastCss)
                    }else {
                        toast.success('Đơn đề nghị gia hạn thành công', toastCss)
                    }
                });
            }else{
                toast.error('Đơn đề nghị gia hạn chỉ chấp nhận định dạng: doc, docx, pdf, png, jpg', toastCss)
                return;
            }
        }
      
        

        
    };

    const convertDataSubmit = ( ) => {
        const today = new Date();

         return{
            NoPlace: noPlate.licensePlate,
            MonthToExtend: 6,
            File: selectedFile,
            fullName: noPlate.fullName,
            phoneNumber: noPlate.phoneNumber,
            ExtendDatetime: today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
        }
    }
    const [noPlate, setnoPlate] = useState([])
    // bien so 
    useEffect(() => {
        dispatch(showListWiner()).then((res) =>  {
            setnoPlate(res.payload.items[0]);
          
        }).catch((err) => {
         
       });
   }, []);


    // profile 
    useEffect(() => {
        dispatch(fetchUserProfile());
    }, []);
    const profile = useSelector( state => {
        return (state.user.currentUser)
    })
    const fullName = profile?.name || '';
    const phoneNumber = profile?.phoneNumber || '';
   
 
    // export doc
    const generate = () => {
        const documentCreator = new DocumentCreator();
        const doc = documentCreator.create([
            profile,
            noPlate
        ]);
    
        Packer.toBlob(doc).then(blob => {
           saveAs(blob, 'Don_xin_gia_han_bien_so '+noPlate.licensePlate+ ".docx");
          console.log("Document created successfully");
        });
    }

      

    return (
        <div className="container">
            
            <div className="row mt-5">
                <div className="col-md-3">
                    <div className="profile-left">
                        <Sidebar pageCurrent={pageCurrent}/>
                    </div>
                </div>
                <div className="col-md-9">
                    <div className="profile-right border rounded p-2 bg-light">
                    <div className="row">
                            <div className="col-md-12">
                                <div>
                                    <h1 className="heading-28-bold neutral-100 text-center"> 
                                        Đơn đề nghị gia hạn
                                    </h1>
                                </div>
                                <hr className="mb-3" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                
                                {/* Table */}
                                <Formik 
                                initialValues={{ NoPlace: noPlate.licensePlate, File: "" }}
                             
                                onSubmit={(values, { resetForm }) => { 
                                    handleSubmit(values, noPlate); 
                                    resetForm(); 
                                }}
                                >
                                    {({ errors, touched }) => (
                                        <Form>
                                        <div className="mt-lg-4">
                                        
                                            
                                            <div className="mt-3">
                                                <span className="fw-bold">Họ và tên:</span> {fullName}
                                            </div>
                                            <div className="mt-3">
                                                <span className="fw-bold">Số điện thoại:</span> {phoneNumber}
                                            </div>
                                            <div className="mt-3">
                                                <span className="fw-bold">Chọn biển số gia hạn: </span> {noPlate.licensePlate}
                                            
                                            </div>
                                            <div className="mt-3">
                                                <label className="fw-bold">Đơn đề nghị gia hạn: </label>
                                                <a className="btn btn-sm btn-primary ms-2" onClick={generate}>Tải mẫu đơn</a>
                                                <div>Bạn điền thông tin đầy đủ, ký tên vào đơn xin đề nghị gia hạn </div>
                                                <div>
                                                <Field  name="File" type={"file"} className="input mt-2" onChange={changeHandler} value={fileVal} />
                                                {errors.File || touched.File ? ( <div>{errors.File}</div> ) : null}
                                                </div>
                                            </div>
                                            
                                            {errors.expect || touched.expect ? <div className="notify-warning">{errors.expect}</div> : null}
                                            <div className="row mt-4 mb-5">
                                            
                                                <div className="col-md-4">
                                                    <Button className="submit-btn" type={"submit"} >Gửi đi</Button>
                                                </div>
                                            </div>
                                        </div>
                                        </Form>
                                        )}
                                </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    );
}

export default RegisterExtend;
