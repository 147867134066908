import {
  AlignmentType,
  Document,
  Paragraph,
  TextRun,
  Table,
  TableRow,
  TableCell,
  WidthType,
  Footer
} from "docx";

export class DocumentCreator {
  // tslint:disable-next-line: typedef
  create([profile, noPlate]) {
    const document = new Document({
      sections: [
        {
            footers: {
                default: new Footer({ // The standard default footer on every page or footer on odd pages when the 'Different Odd & Even Pages' option is activated
                    children: [new Paragraph({
                         children: [
                            new TextRun({
                                text: `Ghi chú:`,
                                size: 20,
                            }),
                            // new TextRun({
                            //     text: `- (1) Ghi rõ nơi nhận là Cục Cảnh sát giao thông.`,
                            //     size: 20,
                            //     break: 1,
                            // }),
                            new TextRun({
                                text: `- (1) Ghi rõ lý do trong trường hợp sự kiện bất khả kháng hoặc trở ngại khách quan.`,
                                size: 20,
                                break: 1,
                            }),
                            new TextRun({
                                text: `- (2) Thống kê các giấy tờ, tài liệu có xác nhận của cơ quan có thẩm quyền kèm theo.`,
                                size: 20,
                                break: 1,
                            }),
                           
                        ]

                    })],
                }),
                
            },
            children: [
                this.cong_hoa(),
                this.info(profile, noPlate),
                this.bang(),
            ]
        }
        
      ]
    });

        return document;
    }

    cong_hoa() {
        return new Paragraph({
        alignment: AlignmentType.CENTER,
        
        children: [
            new TextRun({
                text: `CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM`,
                bold: true,
                size: 28,
            }),
            new TextRun({
                text: "Độc lập - Tự do - Hạnh phúc",
                break: 1,
                bold: true,
                underline: true,
                size: 28,
            }),
            new TextRun({
                text: "ĐƠN ĐỀ NGHỊ",
                break: 3,
                bold: true,
                size: 28,
            }),
            new TextRun({
                text: "GIA HẠN THỜI HẠN ĐĂNG KÝ XE Ô TÔ TRONG TRƯỜNG HỢP",
                break: 1,
                bold: true,
                size: 28,
            }),
            new TextRun({
                text: "HỢP BẤT KHẢ KHÁNG HOẶC TRỞ NGẠI KHÁCH QUAN",
                break: 1,
                bold: true,
                size: 28,
            }),
            new TextRun({
                text: "Kính gửi: Cục Cảnh sát giao thông",
                break: 2,
                size: 28,
            }),
        ]

        })
    }

    info(profile, noPlate){
        return new Paragraph({
            children: [
                new TextRun({
                        text:`Tên cá nhân/tổ chức: ${profile.name}`,
                        size: 28,
                        break: 2,
                    }
                ),
                new TextRun({
                        text:`Mã định danh:  ${noPlate.identificationNumber} Ngày cấp: ...../...../.....`,
                        size: 28,
                        break: 1,
                    }
                ),
                new TextRun({
                        text:`Nơi cấp: ..................................................................................................................`,
                        size: 28,
                        break: 1,
                    }
                ),
                new TextRun({
                        text:`Nơi cư trú/trụ sở: ${noPlate.address} `,
                        size: 28,
                        break: 1,
                    }
                ),
                new TextRun({
                        text:`Số điện thoại: ${profile.phoneNumber}   Email: ${profile.email}`,
                        size: 28,
                        break: 1,
                    }
                ),
                new TextRun({
                        text:`Đã trúng đấu giá biển số xe ô tô: ${noPlate.licensePlate} tại Phiên đấu giá thứ: ............ngày ${noPlate.wonDateString} với số tiền trúng đấu giá là: ${noPlate.wonPrice} đồng `,
                        size: 28,
                        break: 1,
                    }
                ),
                new TextRun({
                        text:`(Bằng chữ:.............................................................................................................)`,
                        size: 28,
                        break: 1,
                    }
                ),
                new TextRun({
                        text:`Biển số trên có thời hạn đăng ký đến ngày ...../...../.....`,
                        size: 28,
                        break: 1,
                    }
                ),
                new TextRun({
                        text:`Đề nghị Cục Cảnh sát giao thông, Bộ Công an gia hạn đăng ký biển số xe ô tô trúng đấu giá`,
                        size: 28,
                        break: 1,
                    }
                ),
                new TextRun({
                        text:`Lý do:(2).................................................................................................................
                        ................................................................................................................................
                        ................................................................................................................................`,
                        size: 28,
                        break: 1,
                    }
                ),
                new TextRun({
                        text:`Xin gửi kèm theo:(3)...............................................................................................
                        ................................................................................................................................
                        ................................................................................................................................`,
                        size: 28,
                        break: 1,
                    }
                ),
                new TextRun({
                        text:`Tôi xin cam đoan những điều trình bày trên là đúng sự thật, nếu sai tôi xin hoàn toàn chịu trách nhiệm trước pháp luật.`,
                        size: 28,
                        break: 1,
                    }
                ),
            ]
          });
    }

    footer(){
        return new Paragraph({
            alignment: AlignmentType.CENTER,
            
            children: [
                new TextRun({
                    text: `CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM`,
                    bold: true,
                    size: 28,
                }),
            ]
        })
    }

    bang(){
        return  new Table({
            borders: {
                top: { size: 1 },
                bottom: { size: 0 },
                left: { size: 0  , color: "FFFFFF",},
                right: { size: 0},
            },
            
            columnWidths: [4505, 4505],
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 3505,
                                type: WidthType.DXA,
                            },
                            borders: {
                                top: { size: 0 , color: "FFFFFF"},
                                bottom: { size: 0 , color: "FFFFFF"},
                                left: { size: 0  , color: "FFFFFF"},
                                right: { size: 0 , color: "FFFFFF" },
                            },
                            children: [new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: `XÁC NHẬN CỦA  CƠ QUAN CHỨC NĂNG`,
                                        bold: true,
                                        size: 24,
                                        break: 1,
                                    }),
                                ]

                            })],
                        }),
                        new TableCell({
                            width: {
                                size: 5505,
                                type: WidthType.DXA,
                            },
                            borders: {
                                top: { size: 0 , color: "FFFFFF"},
                                bottom: { size: 0, color: "FFFFFF" },
                                left: { size: 0  , color: "FFFFFF"},
                                right: { size: 0 , color: "FFFFFF" },
                            },
                            children: [new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: `...................., ngày .......  tháng  .......  năm......`,
                                        size: 24,
                                        break: 1,
                                    }),
                                    new TextRun({
                                        text: `NGƯỜI LÀM ĐƠN`,
                                        size: 24,
                                        break: 1,
                                        bold: true
                                    }),
                                    new TextRun({
                                        text: `(Ký, ghi rõ họ tên)`,
                                        size: 24,
                                        break: 1,
                                    }),
                                ]

                            })],
                        }),
                    ],
                }),
                
            ],
        });
        
    }
    
}
