import {createSlice} from "@reduxjs/toolkit";
import { showListWiner} from "../../service/winer-service";

const initialState = {
    items: [],
    totalCount: 0
}

const winerSlice = createSlice({
    name: 'winer',
    initialState,
    reducers:{},

    extraReducers: builder => {
        builder.addCase(showListWiner.fulfilled, (state, action) => {
            state.items = [...action.payload.items];
            state.totalCount = action.payload.totalCount;
            console.log(state,111)
        })
    }
})

export default winerSlice.reducer


