import {
  AlignmentType,
  Document,
  Paragraph,
  TextRun,
  Table,
  TableRow,
  TableCell,
  WidthType,
  Footer,
} from "docx";

export class DocumentCreator {
  // tslint:disable-next-line: typedef

  create([profile, noPlate]) {
    const document = new Document({
      sections: [
        {
          footers: {
            default: new Footer({
              // The standard default footer on every page or footer on odd pages when the 'Different Odd & Even Pages' option is activated
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: `____________________________________`,
                      size: 28,
                    }),

                    new TextRun({
                      text: `- (1) Chỉ điền thông tin này nếu khách hàng là tổ chức.`,
                      size: 20,
                      break: 1,
                    }),
                    new TextRun({
                      text: `- (2) Khách hàng nêu rõ lý do.`,
                      size: 20,
                      break: 1,
                    }),
                  ],
                }),
              ],
            }),
          },
          children: [this.cong_hoa(), this.info(profile, noPlate), this.bang()],
        },
      ],
    });

    return document;
  }

  cong_hoa() {
    return new Paragraph({
      alignment: AlignmentType.CENTER,

      children: [
        new TextRun({
          text: `CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM`,
          bold: true,
          size: 28,
        }),
        new TextRun({
          text: "Độc lập - Tự do - Hạnh phúc",
          break: 1,
          bold: true,
          underline: true,
          size: 28,
        }),
        new TextRun({
          text: "ĐƠN ĐỀ NGHỊ HOÀN LẠI TIỀN",
          break: 3,
          bold: true,
          size: 28,
        }),

        new TextRun({
          text: "Kính gửi: Cục Cảnh sát giao thông",
          break: 2,
          size: 28,
        }),
      ],
    });
  }

  info(profile, noPlate) {
    return new Paragraph({
      spacing: {
        line: 330,
      },
      children: [
        new TextRun({
          text: `Tên cá nhân/tổ chức: ${profile.name}`,
          size: 28,
          break: 2,
        }),
        new TextRun({
          text: `Mã định danh (số CCCD/mã số thuế):  ${noPlate.identificationNumber}`,
          size: 28,
          break: 1,
        }),
        new TextRun({
          text: `Ngày cấp: ................. Nơi cấp: ..............................................................................`,
          size: 28,
          break: 1,
        }),
        new TextRun({
          text: `Nơi cư trú/trụ sở: ${noPlate.fullAddress} `,
          size: 28,
          break: 1,
        }),
        new TextRun({
          text: `Người đại diện theo pháp luật(1): .........................................................................`,
          size: 28,
          break: 1,
        }),

        new TextRun({
          text: `Số CCCD:........................................................................... Ngày cấp: .................`,
          size: 28,
          break: 1,
        }),
        new TextRun({
          text: `Nơi cấp:..................................................................................................................`,
          size: 28,
          break: 1,
        }),
        new TextRun({
          text: `Email:.....................................................................................................................`,
          size: 28,
          break: 1,
        }),
        new TextRun({
          text: `Số điện thoại:.......................................................................................................... `,
          size: 28,
          break: 1,
        }),
        new TextRun({
          text: `Đã trúng đấu giá biển số xe ô tô: ................ tại Phiên đấu giá thứ: .............. ngày ....../...../...... với số tiền trúng đấu giá là: ....................... đồng `,
          size: 28,
        }),

        new TextRun({
          text: `(Bằng chữ: ........... ..............................................................................................................................).`,
          size: 28,

          italics: true,
        }),
        new TextRun({
          text: ` Số tiền đặt cọc đã nộp: .................................. đồng, số tiền còn lại phải nộp là: ..................... đồng `,
          size: 28,
          break: 1,
        }),
        new TextRun({
          text: `(Bằng chữ: ..........................................................................).`,
          size: 28,
          italics: true,
        }),
        new TextRun({
          text: ` Ngày ...../...../..... tôi/chúng tôi đã nộp số tiền:......................... đồng`,
          size: 28,
          break: 1,
        }),
        new TextRun({
          text: ` (Bằng chữ: .................................................................................................)`,
          size: 28,
          italics: true,
        }),
        new TextRun({
          text: ` vào tài khoản số “1410123456789” của Cục Cảnh sát giao thông mở tại Ngân hàng Agribank - Chi nhánh Mỹ Đình.`,
          size: 28,
        }),

        new TextRun({
          text: `Tôi/chúng tôi làm đơn này đề nghị Cục Cảnh sát giao thông hoàn lại số tiền: .................. đồng `,
          size: 28,
          break: 1,
        }),
        new TextRun({
          text: `(Bằng chữ:................................................................................).`,
          size: 28,
          italics: true,
        }),

        new TextRun({
          text: `Lý do xin hoàn lại tiền(2):.....................................................................................
                        ................................................................................................................................
                        ................................................................................................................................
                        ................................................................................................................................
                        ................................................................................................................................`,
          size: 28,
          break: 1,
        }),

        new TextRun({
          text: `Thông tin nhận lại tiền như sau:`,
          size: 28,
          break: 1,
        }),
        new TextRun({
          text: ` - Chủ tài khoản: ....................................................................................................`,
          size: 28,
          break: 1,
          left: 50,
        }),
        new TextRun({
          text: ` - Số tài khoản: .......................................................................................................`,
          size: 28,
          break: 1,
        }),
        new TextRun({
          text: ` - Ngân hàng: .........................................................................................................`,
          size: 28,
          break: 1,
        }),
        new TextRun({
          text: `Rất mong Cục Cảnh sát giao thông hỗ trợ để tôi/chúng tôi được nhận lại khoản tiền trên.`,
          size: 28,
          break: 1,
        }),
        new TextRun({
          text: `Tài liệu kèm theo đơn:`,
          size: 28,
          break: 1,
          italics: true,
        }),
        new TextRun({
          text: `- Bản sao CCCD/Giấy chứng nhận đăng ký kinh doanh.`,
          size: 28,
          break: 1,
          italics: true,
        }),
        new TextRun({
          text: `- Bản sao CCCD của người đại diện theo pháp luật đối với trường hợp là tổ chức.`,
          size: 28,
          break: 1,
          italics: true,
        }),
        new TextRun({
          text: `- Hình ảnh giao dịch thanh toán thành công có hiển thị mã thanh toán.`,
          size: 28,
          break: 1,
          italics: true,
        }),
        new TextRun({
          text: `Trân trọng cảm ơn!`,
          size: 28,
          break: 1,
        }),
      ],
    });
  }

  footer() {
    return new Paragraph({
      alignment: AlignmentType.CENTER,

      children: [
        new TextRun({
          text: `CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM`,
          bold: true,
          size: 28,
        }),
      ],
    });
  }

  bang() {
    return new Table({
      borders: {
        top: { size: 1 },
        bottom: { size: 0 },
        left: { size: 0, color: "FFFFFF" },
        right: { size: 0 },
      },

      columnWidths: [4505, 4505],
      rows: [
        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 3505,
                type: WidthType.DXA,
              },
              borders: {
                top: { size: 0, color: "FFFFFF" },
                bottom: { size: 0, color: "FFFFFF" },
                left: { size: 0, color: "FFFFFF" },
                right: { size: 0, color: "FFFFFF" },
              },
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: ``,
                      bold: true,
                      size: 28,
                      break: 1,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              width: {
                size: 5505,
                type: WidthType.DXA,
              },
              borders: {
                top: { size: 0, color: "FFFFFF" },
                bottom: { size: 0, color: "FFFFFF" },
                left: { size: 0, color: "FFFFFF" },
                right: { size: 0, color: "FFFFFF" },
              },
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: `...................., ngày .......  tháng  .......  năm......`,
                      size: 28,
                      break: 1,
                    }),
                    new TextRun({
                      text: `Người làm đơn`,
                      size: 28,
                      break: 1,
                      bold: true,
                    }),
                    new TextRun({
                      text: `(Ký, đóng dấu, ghi rõ họ tên)`,
                      size: 24,
                      break: 1,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),
      ],
    });
  }
}
