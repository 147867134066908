import Button from "../../components/core/button/button-default/button";
import "../../custom.scss"
import {useEffect, useState} from "react";

import {showListQA} from "../../service/q-a-service";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import Pagnigation from "../../components/core/pagnigation/pagnigation";
import Sidebar from "../../components/Sidebar";
import { useLocation } from "react-router-dom";

const HistoryQA = () => {
    const [pageCurrent, setPageCurrent] = useState('');
    const location = useLocation();
    useEffect(() => {
        let lastPart = location.pathname.split("/").pop();;
        setPageCurrent(lastPart);
    }, [location.pathname]);

    
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const questions = useSelector(state => {
        return state.qA.items
    })

    const PAGESIZE = {
        COMMON: 10,
    }

    let initFilter = {
        MaxResultCount: PAGESIZE.COMMON,
        SkipCount: 0,
        serialNumber: '',
        fromDate: '',
        toDate: '',
        sortType: 'DESCEND',
        sorting: 'creationTime'
    };
    const [totalCount, setTotalCount] = useState(0);
    const [filter, setFilter] = useState(initFilter);
    useEffect(() => {
        dispatch(showListQA()).then((res) => {
            let {totalCount} = res.payload;
            setTotalCount(totalCount)
        }).catch((err) => {
            console.log(err)});
    }, []);

    const handlePageChange = (page) => {
        let newFilter = { ...filter };
        newFilter.SkipCount = page * 10;
        setFilter(newFilter);
        dispatch(showListQA(newFilter))

    }

    const handlePerRowsChange = async (newPerPage, page) => {
        let newFilter = { ...filter };
        newFilter.SkipCount = page * 10;
        newFilter.MaxResultCount = newPerPage;
        setFilter(newFilter);
        dispatch(showListQA(newFilter))
    }


    const paginate = pageNumber => setFilter(pageNumber);

    return (
        <>
       
            <div>
                <div className="container">
                      {/* Page title */}
                   

                    <div className="row mt-5">
                            <div className="col-md-3">
                                <div className="profile-left">
                                    <Sidebar pageCurrent={pageCurrent}/>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="profile-right border rounded p-2">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div>
                                                    <h1 className="heading-28-bold neutral-100 text-center">Lịch sử câu hỏi</h1>
                                            </div>
                                            <hr className="mb-3" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                               
                                                {/* Table */}
                                                <div>
                                                    <div className="">
                                                        <table className="table ">
                                                            <thead className="bg-neutral-10">
                                                            <tr>
                                                                <th
                                                                    scope="col"
                                                                    className="body-16-medium neutral-60 text-center"
                                                                >
                                                                    STT
                                                                </th>
                                                                <th scope="col" className="body-16-medium neutral-60">
                                                                    Tiêu đề
                                                                </th>
                                                                
                                                                <th scope="col" className="body-16-medium neutral-60 text-center">
                                                                    Trạng thái
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    className="body-16-medium neutral-60 text-center"
                                                                >
                                                                    Thao tác
                                                                </th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {questions?.map((item, rowIndex) => (
                                                                <tr>
                                                                    <td className="body-16-semibold neutral-100 text-align-center">{filter.SkipCount + rowIndex + 1}</td>
                                                                    <td className="body-16-semibold neutral-100">{item.title}</td>
                                                                     <td className="body-16-semibold neutral-100 text-align-center">
                                                                        {item.status == 2 ? ('Hoàn thành') :  ('')}
                                                                        {item.status == 1 ? ('Đang xử lý') :  ('')}
                                                                        {item.status == 0 ? ('Mới tạo') :  ('')}
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <Button text="Chi tiết" onClick={()=>{navigate(`/api/app/q-a-detail/${item.id}`)}}/>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                            </tbody>
                                                        </table>
                                                        <Pagnigation
                                                            postsPerPage={PAGESIZE}
                                                            totalPosts={totalCount}
                                                            paginate={paginate}
                                                            handlePageChange={handlePageChange}
                                                            handlePerRowsChange={handlePerRowsChange}
                                                        />
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                         
                    </div>
                </div>
            </div>
        </>
    )
}
export default HistoryQA