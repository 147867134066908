import Button from "../../components/core/button/button-default/button";
import TextArea from "../../components/core/form/text-area/text-area";
import "../../custom.scss"
import { useLocation } from "react-router-dom";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {createQA} from "../../service/q-a-service";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import Input from "../../components/core/form/input/input";
import {toast} from "react-toastify";
import {MESSAGE_SUCCESS} from "../../config/message";
import {toastCss} from "../../config/toasterConfig";
import Sidebar from "../../components/Sidebar";

const QA = () => {
    const [pageCurrent, setPageCurrent] = useState('');
    const location = useLocation();
    useEffect(() => {
        let lastPart = location.pathname.split("/").pop();;
        setPageCurrent(lastPart);
    }, [location.pathname]);


    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(createQA())
    }, []);

    const InputSchema = Yup.object().shape({
        title: Yup.string()
            .required("Vui lòng chọn câu hỏi cần giải đáp!"),
        description: Yup.string()
            .required("Vui lòng nhập các thông tin cần hỗ trợ!"),
    })

    const handleSubmit = async (value) => {
        const dataBody = convertDataSubmit(value);

        dispatch(createQA(dataBody)).then(() => {
            toast.success(MESSAGE_SUCCESS.Q_A_SUCCESS, toastCss)
        });
    };

    const convertDataSubmit = (value) => {
        return{
            parentId : value?.parentId || null,
            title: value.title,
            description: value.description
        }
    }

    return (
        <>
        
            <div>
                <div className="container">
                   
                    <div className="row mt-5">
                        <div className="col-md-3">
                            <div className="profile-left">
                                <Sidebar pageCurrent={pageCurrent}/>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="profile-right border rounded p-2 bg-light">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div>    
                                            <h1 className="heading-28-bold neutral-100 text-center">Hỗ trợ khách hàng</h1>
                                        </div>
                                        <hr className="mb-3" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        
                                            {/* Table */}
                                            <Formik
                                                initialValues={{ title: '', description: ''}}
                                                validationSchema={InputSchema}
                                                onSubmit={(values, {resetForm}) => {
                                                    handleSubmit(values)
                                                    resetForm()
                                                }}
                                            >   
                                                {({ errors, touched }) => (
                                                    <Form>
                                                        <div className="">
                                                            <div className=" fw-bold"><label>Chủ đề câu hỏi</label></div>
                                                            <Field
                                                                as={Input}
                                                                name="title"
                                                                type={'text'}
                                                                className="mt-5"
                                                            />
                                                            {errors.title && touched.title ? (
                                                                <div className='small text-danger'>{errors.title}</div>
                                                                ) : null}

                                                            <div className="mt-3 fw-bold"><label>Nội dung câu hỏi</label></div>
                                                            <Field
                                                                as={TextArea}
                                                                name="description"
                                                                type={'text'}
                                                                className="mt-5"
                                                            />
                                                            {errors.description && touched.description ? (
                                                                <div className='small text-danger'>{errors.description}</div>
                                                                ) : null}
                                                            <div className="row mt-4 mb-5">
                                                                <div className="col-md-2">
                                                                    <Button
                                                                        className="submit"
                                                                        text="Gửi"
                                                                        type={'submit'}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default QA