import {createSlice} from "@reduxjs/toolkit";
import {getExtendMessage, showListRegisterList } from "../../service/register-list";

const initialState = {
    items: [],
    totalCount: 0
}

const reqisterSlice = createSlice({
    name: 'register-list',
    initialState,
    reducers:{
        setItems: (state, action) => {
            state.items = [...state.items, action.payload];
        },  
    },
    extraReducers: builder => {
        builder.addCase(showListRegisterList.fulfilled, (state, action) => {
            state.items = [...action.payload.items];
            state.totalCount = action.payload.totalCount;
        })
       
    }
})

export const { setItems } = reqisterSlice.actions;
export default reqisterSlice.reducer


