import {createAsyncThunk} from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import {ACCESS_TOKEN_KEY} from "../config/token";
import {_convertObjectToQuery} from "./winer-service";
import axiosInstance from "../config/customAxios";

const token = Cookies.get(ACCESS_TOKEN_KEY)
export const showListQA = createAsyncThunk(
    'q-a/showListQA',
    async (body) => {
        let res = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/api/app/q-a/async-by-user?${_convertObjectToQuery(body)}&sortType=DESCEND`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return res.data
    }
)

export const createQA = createAsyncThunk(
    'q-a/createQA',
    async (data) => {
         const res = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/api/app/q-a/async-by-user`, data)
        return res.data
    }
)
export const createQAU = createAsyncThunk(
    'q-a/createQAU',
    async (data) => {
         const res = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/api/app/q-a`, data)
        return res.data
    }
)

export const detailQA = createAsyncThunk(
    'q-a/detailQA',
    async (id) => {
        try{
            let res = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/api/app/q-a/${id}/async-by-user`)
            return res.data
        } catch (e){
            
        }

    }
)