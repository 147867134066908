import "../../custom.scss";
import "./scss/profile.scss"
import "../../components/core/button/button-default/button"
import Input from "../../components/core/form/input/input";
import Sidebar from "../../components/Sidebar";
import {useDispatch, useSelector} from "react-redux";
import { useLocation } from "react-router-dom";
import {useEffect, useState} from "react";
import {fetchUserProfile} from "../../service/user-service";

export const Profile = () => {
    const [pageCurrent, setPageCurrent] = useState('');
    const location = useLocation();
    useEffect(() => {
        let lastPart = location.pathname.split("/").pop();;
        setPageCurrent(lastPart);
    }, [location.pathname]);


    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchUserProfile());
    }, []);
    const profile = useSelector( state => {
        return (state.user.currentUser)
    })
    return (
        <>
          
            <div>
                <div className="container">
                    
 
                    <div>
                        {profile != null &&
                        <div className="row mt-5">
                            <div className="col-md-3">
                                <div className="profile-left">
                                    <Sidebar pageCurrent={pageCurrent}/>
                                </div>
                            </div>
                            <div className="col-md-9">
                                
                                <div className="profile-right border rounded p-2">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div>
                                                <h1 className="heading-28-bold neutral-100 text-center">Thông tin tài khoản</h1>
                                            </div>
                                            <hr className="mb-3"/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className="labels mt-24">Họ Tên</label>
                                            <Input type="text" value={profile.name} disabled={true}/>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="labels mt-24">Email</label>
                                            <Input type="email" value={profile.email} disabled={true}/>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="labels mt-24">Số điện thoại</label>
                                            <Input type="number" value={profile.phoneNumber} disabled={true}/>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="labels mt-24">ID tài khoản</label>
                                            <Input type="number" value={profile.winnerId} disabled={true}/>
                                        </div>
                                    </div>    
                                </div>    
                            </div>  
                           
                           
                        </div>}
                       
                    </div>
                </div>
            </div>
        </>
    )
}

export default Profile