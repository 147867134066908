import { getAllLoginCounter, keepAllLoginCounter } from "../../pages/login/login.counter";

export const numInArray = (num, arr) => {
    if (arr) {
        const found = arr?.find(element => element === num);
        return found ? true : false
    }
    return false;
}
export function clearLocalStorage() {
    // get all keys which need to be kept after clear
    const counters = getAllLoginCounter();
    localStorage.clear();
     // re-store all the keys which need to be kept.
    keepAllLoginCounter(counters);
   
}