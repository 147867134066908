import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_API } from "../config/constant";
import axiosInstance from "../config/customAxios";

export const fetchUserProfile = createAsyncThunk(
    'user/fetchUserProfile',
    async () => {
    try {
        const res = await axiosInstance.get(`${URL_API}/api/app/account/customer-profile`);
        return res.data
    } catch (error) {
        console.log(error)
    }
})


