import {createAsyncThunk} from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import {ACCESS_TOKEN_KEY} from "../config/token";
import {_convertObjectToQuery} from "./winer-service";
import axiosInstance from "../config/customAxios";

const token = Cookies.get(ACCESS_TOKEN_KEY)

export const showListCat = createAsyncThunk(
    'cat/showListCat',
    async () => {
          let res = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/api/app/category/categoies`, {
        })
 
        return res.data
    }
)


 