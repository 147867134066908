import React from 'react';
import { Outlet } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import MenuLogin from "../../components/header/MenuLogin";



const Home = () => {
    return (
        <div>
            <Header />
            <MenuLogin />
            <Outlet />
            <Footer />
        </div>
    );
};

export default Home;