import "./scss/footer.scss"

const Footer = () => {
    return(
        <>
            <div className="main-footer ">
                <hr/>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="d-flex mw-300">
                                <div className="d-flex ">
                                    <img src="/assets/images/header/img.png" className="h-44 w-39"/>
                                </div>
                                <div className="padding-left-15">
                                    <div className="body-14-bold primary-1 mg-0-0-4-13">
                                        BỘ CÔNG AN
                                    </div>
                                    <div className="body-14-bold primary-1 mg-0-0-0-13">
                                        CỤC CẢNH SÁT GIAO THÔNG
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <ul className="list-unstyled">
                                <li className="body-14-medium mg-top-8">Cổng thông tin điện tử bộ công an</li>
                                <li className="body-14-medium mg-top-8">Trụ sở: 44 Yết Kiêu - Hoàn Kiếm - Hà Nội;</li>
                                <li className="body-14-medium mg-top-8">Điện Thoại: 069.2343647;</li>
                                <li className="body-14-medium mg-top-8">Email: daugiabienso@bocongan.vn</li>
                            </ul>
                        </div>
                        <div className="col-md-4">
                            <p className=" neutral-60 caption-12-medium">
                                Bản quyền thuộc về Bộ Công an.<br/>
                                Khi sử dụng lại thông tin, đề nghị ghi rõ nguồn "Cổng TTĐT Bộ Công an"
                            </p>
                        </div>
                        
                    </div>
                   
                </div>
            </div>
        </>
    )
}
export default Footer;