import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {showListWiner, downloadQD, download} from "../../service/winer-service";
import moment from "moment";


const AuctionHistory = () => {

    const PAGESIZE = {
        COMMON: 10,
    }

    let initFilter = {
        MaxResultCount: PAGESIZE.COMMON,
        SkipCount: 0,
        serialNumber: '',
        fromDate: '',
        toDate: '',
    };
    const [totalCount, setTotalCount] = useState(0);
    const [filter, setFilter] = useState(initFilter);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(showListWiner()).then((res) =>  {
            let {totalCount} = res.payload;
            setTotalCount(totalCount)
        }).catch((err) => {
            console.log(err)});
    }, []);

    const search = window.location.search;
    const params = new URLSearchParams(search);

    let winers = useSelector(state => { 
        return state.winer.items;
    })

    useEffect(() => {
        if (params.get('serialNumber')) {
            let newFilter = {...filter};
            newFilter.serialNumber = params.get('serialNumber') || '';
            setFilter(newFilter);
            dispatch(showListWiner(newFilter));
        }
    }, [params.get('serialNumber')]);


    // down load quyet dinh 
    const handleDownloadQD = (item) => {
        onDownloadQD(item)
  
    }

    const onDownloadQD = async (item) => {
        try {
             dispatch(downloadQD(item.id)).then((res) => {
                const urlFile = item.fileWinAuction;
                const ext = urlFile.split(".").pop()

                const blob = new Blob([res.payload], {'type': 'application/zip'})
                const url = window.URL.createObjectURL(blob)
 
                const a = document.createElement('a')
                a.style.display = 'none'
                a.href = url

                a.download = 'QD'+item.id+'.zip';
                a.click()
                return window.URL.revokeObjectURL(url) 


             });
       } catch (error) {
          console.log(error)
       
       }
   }

//    down load van ban 
    const handleDownload  = (item) => {
        onDownload(item)

    }
    const onDownload = async (item) => {
        try {
            dispatch(download(item.id)).then((res) => {
                const urlFile = item.urlAuctionDocument;
                const ext = urlFile.split(".").pop()
                console.log(ext);

                const blob = new Blob([res], {'type': 'application/'+ext})
                const url = window.URL.createObjectURL(blob)
                console.log(url);

                const a = document.createElement('a')
                a.style.display = 'none'
                a.href = url

                a.download = 'VB'+urlFile;
                a.click()
                return window.URL.revokeObjectURL(url) 


            });
    } catch (error) {
        console.log(error)
    
    }
     
}

     

    return (
        <>
         
            <div>
                <div className="container">
                    {/* Page title */}
                    <div>
                        <h1 className="heading-28-bold neutral-100 text-center mt-5">Lịch sử đấu giá</h1>
                    </div>
                    {/* Search */}
                    {/* <div className="d-flex gap-lg-4 align-items-end justify-content-between">
                        <Input type="date" label="Thời gian bắt đầu"
                               onChange={handleSearchChangeFromDate}/>
                        <Input type="date" label="Thời gian kết thúc"
                               onChange={handleSearchChangeToDate}/>
                        <Input type="text" label="Biển số"
                               placeholder="Nhập biển số"
                               onKeyDown={handleSearchKeyDown}
                               onChange={handleSearchChange}/>
                        <Button text="Tìm kiếm" onClick={handleSearch} onKeyDown={handleSearchKeyDown}
                        />
                    </div> */}

                    {/* Table */}
                    <div className="mt-lg-4">
                       <div className="mobile-table" style={{ overflowX:'scroll'}}>
                            <table className="table">
                                    <thead className="bg-neutral-10">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="body-16-medium neutral-60 justify-content-center text-center"
                                        >
                                            STT
                                        </th>
                                        <th scope="col" className="body-16-medium neutral-60">
                                            Biển số
                                        </th>
                                        <th scope="col" className="body-16-medium neutral-60">
                                           Họ tên
                                        </th>
                                        <th scope="col" className="body-16-medium neutral-60">
                                           Số điện thoại
                                        </th>
                                        <th scope="col" className="body-16-medium neutral-60 text-center">
                                            Giá kết thúc
                                        </th>
                                        <th scope="col" className="body-16-medium neutral-60 text-center">
                                            Ngày đấu giá
                                        </th>
                                        <th scope="col" className="body-16-medium neutral-60 text-center">
                                           Đã nộp
                                        </th>
                                        <th scope="col" className="body-16-medium neutral-60 text-center">
                                           Thời hạn nộp tiền
                                        </th>
                                        {/* <th
                                            scope="col"
                                            className="body-16-medium neutral-60 text-center"
                                        >
                                            Trạng thái
                                        </th> */}
                                        
                                      
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {winers?.map((item, rowIndex) => (
                                        <tr>
                                            <td className="body-16-semibold neutral-100 text-center">{filter.SkipCount + rowIndex + 1}</td>
                                            <td className="body-16-semibold neutral-100">{item.serialNumber}</td>
                                            <td className="body-16-semibold neutral-100">{item.fullName}</td>
                                            <td className="body-16-semibold neutral-100">{item.phoneNumber}</td>
                                            <td className="body-16-semibold neutral-100 text-center">{item.wonPrice ? item.wonPrice.toLocaleString('it-IT', {
                                                style: 'currency',
                                                currency: 'VND'
                                            }) : '-'}</td>
                                            <td className="body-16-semibold neutral-100 text-align-center">{item && item?.wonDateString ? item.wonDateString : '-'}</td>
                                            
                                            {/* so tien nop  */}
                                            <td className="body-16-semibold neutral-100 text-center">{item.wonPricePaid ? item.wonPricePaid.toLocaleString('it-IT', {
                                                style: 'currency',
                                                currency: 'VND'
                                            }) : '-'}
                                            </td>
                                            {/* ngay  */}
                                            <td className="body-16-semibold neutral-100 text-align-center">
                                            
                                                {item && item.expirePayment ? moment(new Date(item.expirePayment)).format('DD/MM/YYYY') : '-'}
                                            </td>
                                            {/* trang thai  */}
                                            {/* <td className="semantic-success-1 text-center">
                                                {item.status === 0?('-'):item.status === 3?(<span className='text-success'>Thành công</span>):item.status === 2?('Hủy kết quả'):item.status === 1?('Thu hồi biển số'):item.status === 4?('Vi phạm'):('')}
                                            </td> */}
                                              
                                        </tr>
                                        
                                    ))}
                                    </tbody>
                                </table>
                                {/* <Pagnigation
                                    postsPerPage={PAGESIZE}
                                    totalPosts={totalCount}
                                    paginate={paginate}
                                    handlePageChange={handlePageChange}
                                    handlePerRowsChange={handlePerRowsChange}
                                /> */}

                       </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AuctionHistory;
