import React from "react";
import Label from "../label/label";
import "./scss/text-area.scss";

const Input = (props) => {
    return (
        <>
            <div className="d-flex flex-column w-100">
                <Label text={props.label} />
                <textarea
                    className="input resize-none"
                    value={props.value}
                    disabled={props.disabled}
                    placeholder={props.placeholder}
                    id={props.id}
                    name={props.name}
                    onChange={props.onChange}
                    rows="5"
                    cols="50"
                />
            </div>
        </>
    );
};

export default Input;
