const Sidebar = (props) => {
    return (
        <>
            <ul class="list-group">
                                       
                <a href={"/api/app/profile"} class={`list-group-item list-group-item-action ${props.pageCurrent === 'profile'? 'active':''}`}>Cá nhân</a>
                <a href={"/api/app/q-a"} class={`list-group-item list-group-item-action ${props.pageCurrent === 'q-a'? 'active':''}`}>Hỗ trợ khách hàng</a>
                <a href={"/api/app/q-a-history"} class={`list-group-item list-group-item-action ${props.pageCurrent === 'q-a-history'? 'active':''}`}>Danh sách câu hỏi hỗ trợ</a>
                <a href={"/api/app/register-extend"} class={`list-group-item list-group-item-action ${props.pageCurrent === 'register-extend'? 'active':''}`}>Đơn đề nghị gia hạn</a>
                <a href={"/api/app/register-list"} class={`list-group-item list-group-item-action ${props.pageCurrent === 'register-list'? 'active':''}`}>Danh sách đề nghị gia hạn</a>
                <a href={"/cashback"} class={`list-group-item list-group-item-action ${props.pageCurrent === 'cashback'? 'active':''}`}>Đề nghị hoàn tiền</a>
                {/* <a href={"/sale"} class={`list-group-item list-group-item-action ${props.pageCurrent === 'sale'? 'active':''}`}>Đề nghị chuyển nhượng cho người thừa kế</a> */}
                
            </ul>
        </>
    )
}
export default Sidebar;