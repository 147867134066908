import {configureStore} from '@reduxjs/toolkit';
import homeSlice from "./home-slice/home-slice";
import userSlice from "./user-slice/user-slice";
import winerSlice from "./winer-slice/winer-slice";
import qASlice from "./q-a-slice/q-a-slice";
import newsSlice from "./news-slice/news-slice";
import registerSlice from "./register-slice/register-slice";
import cashbackSlice from "./cashback-slice/cashback-slice";
import doc from "./doc/doc";

const store = configureStore({
    reducer: {
        user: userSlice,
        home: homeSlice,
        winer:winerSlice,
        qA:qASlice,
        news:newsSlice,
        register:registerSlice,
        cashback:cashbackSlice,
        doc:doc,
    },
})
export default store

