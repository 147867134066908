import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { clearLocalStorage } from "../../core/ultis/ultis";
import { fetchUserProfile } from "../../service/user-service";

const initialState = {
    currentUser: null,
    loading: false
}

const userSlice = createSlice({
    name: 'userSlice',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.currentUser = action.payload
            localStorage.setItem('currentUser', JSON.stringify(action.payload));
            localStorage.setItem('isAuthen', true);
        },
        logout: (state, action) => {
            state.currentUser = null;
            Cookies.remove();
            clearLocalStorage();
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchUserProfile.pending, (state, action) => {
                state.loading = true;
                state.currentUser = null;
            })
            .addCase(fetchUserProfile.fulfilled, (state, action) => {
                state.currentUser = action.payload;
                localStorage.setItem('currentUser', JSON.stringify(action.payload));
                localStorage.setItem('isAuthen', true);
                state.loading = false;
            })
            .addCase(fetchUserProfile.rejected, (state, action) => {
                state.currentUser = null;
                state.loading = false;
                localStorage.setItem('currentUser', null);
                localStorage.setItem('isAuthen', false);
            })
    }
})

export const { setUser, logout } = userSlice.actions;
export default userSlice.reducer