import {createAsyncThunk} from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import {ACCESS_TOKEN_KEY} from "../config/token";
import axiosInstance from "../config/customAxios";

export const _convertObjectToQuery = (obj) => {
    let query = '';
    for (let key in obj) {
        if (obj[key] !== undefined) {
            if (query) {
                query += `&${key}=${obj[key]}`;
            } else {
                query += `${key}=${obj[key]}`;
            }
        }
    }
    return query;
}
const token = Cookies.get(ACCESS_TOKEN_KEY)
 
 
export const docAPI = createAsyncThunk(
    'winner-addition/my-addition',
    async () => {
        let res = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/api/app/winner-addition/my-addition`, {
                // responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token}`
                }
        })
        return res.data
    }
)
 
export const docDownloadAPI = createAsyncThunk(
    'winner-addition/file-winner',
    async (item) => {
 
         let res = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/api/app/winner-addition/${item.id}/file-winner?filename=${item.fileAttackURL}`, {
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token}`
                }
        })
        return res.data
    }
)
export const docDownloadAPI2 = createAsyncThunk(
    'winner-addition/file-winner',
    async (item) => {
 
         let res = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/api/app/winner-addition/${item.id}/file-winner2?filename=${item.fileAttackURL}`, {
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token}`
                }
        })
        return res.data
    }
)
export const downloadHDList = createAsyncThunk(
    'invoices-vnpt/my-einvoice',
    async (item) => {
 
         let res = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/api/app/invoices-vnpt/my-einvoice`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
        })
        return res.data
    }
)


 