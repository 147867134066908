import {createSlice} from "@reduxjs/toolkit";
import {cashbackListAPI} from "../../service/cashback";

const initialState = {
    items: [],
    totalCount: 0,
 }

const cashbackSlice = createSlice({
    name: 'back',
    initialState,
    reducers:{
        setItems: (state, action) => {
            state.items = [...state.items, action.payload];
        },  
    },
    extraReducers: builder => {
        builder.addCase(cashbackListAPI.fulfilled, (state, action) => {
            state.items = [...action.payload.items];
            state.totalCount = action.payload.totalCount;
        })
     
       
    }
})

export const { setItems } = cashbackSlice.actions;
export default cashbackSlice.reducer


