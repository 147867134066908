import React from "react";
import {useEffect, useState} from "react";
import { Field, Form, Formik } from "formik";
import {cashbackAPI, cashbackListAPI, cashbackCancelAPI } from "../../service/cashback";
import { Button } from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import './index.scss'
import {toast} from "react-toastify";
import {MESSAGE_SUCCESS} from "../../config/message";
import {toastCss} from "../../config/toasterConfig";  
import SelectNoPlate from "./SelectNoPlate";
import Pagnigation from "../../components/core/pagnigation/pagnigation";
import {fetchUserProfile} from "../../service/user-service";
import Sidebar from "../../components/Sidebar";
import { useLocation } from "react-router-dom";
import { DocumentCreator } from "./cv-generator";
import { saveAs } from "file-saver";
import { Packer } from "docx";
import * as Yup from 'yup';
import {showListWiner} from "../../service/winer-service";

function Cashback() {
    const [pageCurrent, setPageCurrent] = useState('');
    const location = useLocation();
    useEffect(() => {
        let lastPart = location.pathname.split("/").pop();;
        setPageCurrent(lastPart);
    }, [location.pathname]);

    
    const dispatch = useDispatch();
    const cashbackData = useSelector(state => {
        return state.cashback.items
    })

      // profile 
      useEffect(() => {
        dispatch(fetchUserProfile());
    }, []);
    const profile = useSelector( state => {
        return (state.user.currentUser)
    })
    const winnerId = profile?.winnerId || '';
    const fullName = profile?.name || '';
    const phoneNumber = profile?.phoneNumber || '';

    
    const [noPlate, setnoPlate] = useState([])
    // bien so 
    useEffect(() => {
        dispatch(showListWiner()).then((res) =>  {
            setnoPlate(res.payload.items[0]);
            
        }).catch((err) => {
         
       });
   }, []);

    const PAGESIZE = {
        COMMON: 10,
    }

    let initFilter = {
        MaxResultCount: PAGESIZE.COMMON,
        SkipCount: 0,
        WinnerId: winnerId, 
        claimType: 0
    };

    
    // danh sach ho so 
    const [totalCount, setTotalCount] = useState(0);
    const [filter, setFilter] = useState(initFilter);
    useEffect(() => {
        dispatch(cashbackListAPI({claimType: 0})).then((res) => {
            let {totalCount} = res.payload;
            setTotalCount(totalCount)
        }).catch((err) => {
            console.log(err)});
    }, []);
 

    // Initially, no file is selected
    const [selectedFile, setSelectedFile] = useState();
	const [isFilePicked, setIsFilePicked] = useState(false);
    const [fileVal,setFileVal] = useState();
 
   
    const changeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
		setIsFilePicked(true);
	};

    // const [selectedFile2, setSelectedFile2] = useState();
    // const changeHandler2 = (event) => {
	// 	setSelectedFile2(event.target.files[0]);
 	// };

    // const [selectedFile3, setSelectedFile3] = useState();
    // const changeHandler3 = (event) => {
	// 	setSelectedFile3(event.target.files[0]);
 	// };
    // const [selectedFile4, setSelectedFile4] = useState();
    // const changeHandler4 = (event) => {
	// 	setSelectedFile4(event.target.files[0]);
 	// };
    // const [selectedFile5, setSelectedFile5] = useState();
    // const changeHandler5 = (event) => {
	// 	setSelectedFile5(event.target.files[0]);
 	// };

    const handleSubmit = async (value) => {
     
        const dataBody = convertDataSubmit(value);
        if(!dataBody.CodeLogin){
            toast.error('Mã người trúng đấu giá không được để trống', toastCss)
            return;
        }
        if(dataBody.Files[0] === undefined){
            toast.error('Đơn  không được để trống', toastCss)
            return;
        }else{
            const ext = dataBody.Files[0].name.split(".").pop();
            if(ext === 'doc' || ext === 'docx' || ext === 'pdf' || ext === 'png' || ext === 'jpg' || ext === 'jpeg'){
                dispatch(cashbackAPI(dataBody)).then((res) => {
                    console.log(res);
                    if(res.error){
                        toast.error('Lỗi gửi đơn, có thể bạn đã gửi 1 lần vui lòng chờ kết quả', toastCss)
                    }else {
                        toast.success('Gửi thành công', toastCss)
                    }
                    
                });


            }else{
                toast.error('Đơn chỉ chấp nhận định dạng: doc, docx, pdf, png, jpg', toastCss)
                return;
            }
        }

     
    };
   

    const convertDataSubmit = (value) => {
        
        //  Doc1 = 0 Đơn đề nghị hoàn tiền trúng đấu giá biển số xe ô tô 
        //  Doc2 = 1: Văn bản khai nhận di sản thừa kế hoặc văn bản thỏa thuận phân chia di sản thừa kế đã được công chứng theo quy định pháp luật
        // Doc3 = 2:  Văn bản xác nhận biển số xe ô tô trúng đấu giá;
        //Doc4 = 3: Giấy ủy quyền (nếu có);
        //Doc5 = 4 Căn cước công dân của các đồng thừa kế.
 
        const attach = [];
        if(selectedFile){
            attach.push(
                {"docType": 0,"fileName": selectedFile.name}
            )
        }
        // if(selectedFile2){
        //     attach.push(
        //         {"docType": 1,"fileName": selectedFile2.name}
        //     )
        // }
        // if(selectedFile3){
        //     attach.push(
        //         {"docType": 2,"fileName": selectedFile3.name}
        //     )
        // }
        // if(selectedFile4){
        //     attach.push(
        //         {"docType": 3,"fileName": selectedFile4.name}
        //     )
        // }
        // if(selectedFile5){
        //     attach.push(
        //         {"docType": 4,"fileName": selectedFile5.name}
        //     )
        // }

        return{
            // NoPlace: value.NoPlace,
            CodeLogin: value.CodeLogin,
            // Files: [selectedFile,selectedFile2,selectedFile3,selectedFile4,selectedFile5],
            Files: [selectedFile],
            AttachDocuments: JSON.stringify(attach),
            WinnerId: winnerId,
            claimType:0 
        }
    }
   
   

    const paginate = pageNumber => setFilter(pageNumber);
    const handlePageChange = (page) => {
        let newFilter = { ...filter };
        newFilter.SkipCount = page * 10;
        newFilter.claimType= 0;
      
        setFilter(newFilter);
        dispatch(cashbackListAPI(newFilter))

    }

    const handlePerRowsChange = async (newPerPage, page) => {
        let newFilter = { ...filter };
        newFilter.SkipCount = page * 10;
        newFilter.claimType= 0;
        newFilter.MaxResultCount = newPerPage;        
        setFilter(newFilter);
        dispatch(cashbackListAPI(newFilter))
    }
    
    const [hiddenResults, setHiddenResults] = React.useState(true)
     // huy don  
    const cashbackCancel = async (id) => {
        try {
            dispatch(cashbackCancelAPI(id)).then(() => {
               
                toast.success('Gửi đăng ký thành công', toastCss)
                setHiddenResults(false)

            });
      } catch (error) {
         console.log(error)
      
      }
 
      return;
    }
  
    const ButtonCanel = (claimId) => (
        <button className="btn btn-primary" onClick={()=>cashbackCancel(claimId)}>Hủy đơn</button>
    )
 
    // export doc
    const generate = () => {
        const documentCreator = new DocumentCreator();
        const doc = documentCreator.create([
            profile,
            noPlate
        ]);
    
        Packer.toBlob(doc).then(blob => {
           saveAs(blob, 'Don_de_nghi_hoan_tien_'+noPlate.licensePlate+ ".docx");
          console.log("Document created successfully");
        });
    }

      

    return (
        <div className="container">
            
            <div className="row mt-5">
                        <div className="col-md-3">
                            <div className="profile-left">
                                <Sidebar pageCurrent={pageCurrent}/>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="profile-right rounded border p-3">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div>
                                            <h1 className="heading-28-bold neutral-100 text-center"> 
                                                Nộp đơn đề nghị hoàn tiền
                                            </h1>
                                            <hr className="mb-5" />
                                        </div>
                                        <div>
                                            {/* Table */}
                                            <Formik 
                                                initialValues={{ Files: "",  WinnerId: winnerId, CodeLogin: "" }}
                                            
                                                onSubmit={(values, { resetForm }) => { 
                                                    handleSubmit(values); 
                                                    resetForm(); 
                                                }}
                                                >
                                                    {({ errors, touched }) => (
                                                    <Form>
                                                        <div className=" ">
                                            
                                                        
                                                            <div className="d-flex flex-column w-100">
                                                                <label className="fw-bold">Mã code người trúng đấu giá (*)</label>
                                                                <Field name="CodeLogin" type={'text'} className="input mt-2" />
                                                              
                                                            </div>
                                                            <div className="mt-3">
                                                                <label className="fw-bold">Đơn đề nghị hoàn tiền: </label>
                                                                <a className="btn btn-sm btn-primary ms-2" onClick={generate}>Tải mẫu đơn</a>
                                                                <div>Bạn điền thông tin đầy đủ, ký tên vào đơn xin đề nghị hoàn tiền </div>
                                                                 
                                                            </div>
                                        
                                                            <div className="d-flex flex-column w-100 mt-3">
                                                                <label className="fw-bold">Đơn đề nghị hoàn tiền trúng đấu giá biển số xe ô tô</label>
                                                                <Field  name="Files" type={"file"} className="input mt-2" onChange={changeHandler} value={fileVal}/>
                                                                
                                                            </div>
                                                            {/* <div className="d-flex flex-column w-100 mt-3">
                                                                <label className="fw-bold"> 2. Văn bản khai nhận di sản thừa kế hoặc văn bản thỏa thuận phân chia di sản thừa kế đã được công chứng</label>
                                                                <Field  name="Files" type={"file"} className="input mt-2" onChange={changeHandler2} value={fileVal}/>
                                                             
                                                            </div>
                                                            <div className="d-flex flex-column w-100 mt-3">
                                                                <label className="fw-bold"> 3. Văn bản xác nhận biển số xe ô tô trúng đấu giá</label>
                                                                <Field  name="File" type={"file"} className="input mt-2" onChange={changeHandler3} value={fileVal}  />
                                                               
                                                            </div>
                                                            <div className="d-flex flex-column w-100 mt-3">
                                                                <label className="fw-bold"> 4. Giấy ủy quyền (nếu có)</label>
                                                                <Field  name="File_otion" type={"file"} className="input mt-2" onChange={changeHandler4} value={fileVal} />
                                                            </div>
                                                            <div className="d-flex flex-column w-100 mt-3">
                                                                <label className="fw-bold"> 5. Căn cước công dân của các đồng thừa kế</label>
                                                                <Field  name="File" type={"file"} className="input mt-2" onChange={changeHandler5} value={fileVal} />
                                                              
                                                            </div> */}
                                                        
 
                                                            
                                                            <div className="row mt-4 mb-5 mt-3">
                                                                <div className="col-md-4">
                                                                    <Button className="submit-btn" type={"submit"} >Gửi đi</Button>
                                                                </div>
                                                            </div> 
                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </div>
                                        <div>
                                            <hr />
                                            <h3>Danh sách hồ sơ đã nộp</h3>
                                            <div>
                                                    <div className="mt-lg-4">
                                                        <table className="table ">
                                                            <thead className="bg-neutral-10">
                                                            <tr>
                                                                <th
                                                                    scope="col"
                                                                    className="body-16-medium neutral-60 text-center"
                                                                >
                                                                    STT
                                                                </th>
                                                                <th scope="col" className="body-16-medium neutral-60">
                                                                Họ tên                                    </th>
                                                                
                                                                <th scope="col" className="body-16-medium neutral-60 text-center" >
                                                                Số điện thoại
                                                                </th>
                                                                <th scope="col" className="body-16-medium neutral-60 text-center" >
                                                                Hủy đơn
                                                                </th>
                                                                <th scope="col" className="body-16-medium neutral-60 text-center" >
                                                                    Trạng thái
                                                                </th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {cashbackData?.map((item, rowIndex) => (
                                                                // {item.claimType == 0 ?(
                                                                    <tr key={rowIndex}>
                                                                        <td className="body-16-semibold neutral-100 text-align-center">{filter.SkipCount + rowIndex + 1}</td>
                                                                        <td className="body-16-semibold neutral-100">{fullName}</td>
                                                                        <td className="body-16-semibold neutral-100">{phoneNumber}</td>
                                                                        <td className="body-16-semibold neutral-100 text-center" key={rowIndex}>
                                                                            {item.status == 0? (
                                                                                hiddenResults ? <ButtonCanel claimId={item.id} /> : null
                                                                            ):('')}
                                                                            {item.status == 4? (<span className="text-danger" >Đã hủy</span>):('')}
                                                                        
                                                                        </td>
                                                                        
                                                                        <td className="text-center">
                                                                            {item.status == 0? (<span className="text-dart">Mới gửi</span>):('')}
                                                                            {item.status == 1? (<span className="text-success">Đã hoàn tiền</span>):('')}
                                                                            {item.status == 2? (<span className="text-success">Đang xử lý</span>):('')}
                                                                            {item.status == 3? (<span className="text-success">Đã duyệt</span>):('')}
                                                                            {item.status == 4? (<span className="text-danger">Người đấu giá hủy</span>):('')}
                                                                            {item.status == 5? (<span className="text-danger">Từ chối</span>):('')}

                                
                                                                        </td>
                                                                    </tr>
                                                                // ):('')
                                                                // }
                                                            ))}
                                                            </tbody>
                                                        </table>
                                                        <Pagnigation
                                                            postsPerPage={PAGESIZE}
                                                            totalPosts={totalCount}
                                                            paginate={paginate}
                                                            handlePageChange={handlePageChange}
                                                            handlePerRowsChange={handlePerRowsChange}
                                                        />
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
                                        
        </div>
    );
}

export default Cashback;
