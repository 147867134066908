import Cookies from "js-cookie";
import React, { useEffect, useRef } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosInstance from './config/customAxios';
import { ACCESS_TOKEN_KEY } from "./config/token";
import Routing from "./core/routes/routes";
import "./custom.scss";


import { useDispatch } from 'react-redux';
import { logout, setUser } from "./redux/user-slice/user-slice";

function App() {
  const dispatch = useDispatch();
  const didRequest = useRef(false)
  const accessToken = Cookies.get(ACCESS_TOKEN_KEY)

  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const responseUserInfo = await axiosInstance.get('/api/app/account/customer-profile');
          if (responseUserInfo) {
            dispatch(setUser(responseUserInfo.data));
          }
        }
      } catch (error) {
        if (!didRequest.current) {
          dispatch(logout())
        }
      }
      return () => (didRequest.current = true)
    }

    if (accessToken) {
      requestUser()
    } else {
      dispatch(logout())
    }
  }, []);

  return (
    <>
      <Routing />
      <ToastContainer closeOnClick />
    </>
  );
}

export default App;
