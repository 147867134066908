import React, {Suspense} from "react"
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";

import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";
import ROUTES from "../../config/ROUTES";
import ProtectedRoute from "./protectedRoute";
import Home from "../../pages/home/home";
import AuctionHistory from "../../pages/auction-history/auction-history";
import PageNotFound from "../../components/pageNotFound/PageNotFound";
import QA from "../../pages/q-a/q-a";
import HistoryQA from "../../pages/history-q-a/history-q-a";
import DetailQA from "../../pages/detail-q-a/detail-q-a";
import Profile from "../../pages/profile/profile";
import RegisterExtend from "../../pages/register-extend";
import RegisterList from "../../pages/register-list";
import Doc from "../../pages/doc/doc";
import Cashback from "../../pages/cashback";
import Sale from "../../pages/sale";
 
const Routing = () => {
    return (
        <Router>
            <Suspense fallback={<LoadingComponent/>}>
                <Routes>
                    {ROUTES?.map((route, index) => {
                        if (!route.role) {
                            {/* non auth */}
                            return (
                                <>
                                    <Route key={index} path={route.path} element={route.component}/>
                                </>
                            )
                        } else {
                            return (
                                <>
                                    {/*lich su dau gia*/}
                                    <Route key="winer" path="/api/app/winer" element={<Home/>}>
                                        <Route index element={<ProtectedRoute role={'user'}><AuctionHistory/></ProtectedRoute>}/>
                                        <Route key={index} path={route.path} element={<ProtectedRoute role={route.role}>{route.component}</ProtectedRoute>}/>
                                    </Route>

                                    {/*from gui cau hoi*/}
                                    <Route key="q-a" path="/api/app/q-a" element={<Home/>}>
                                        <Route index element={<ProtectedRoute role={'user'}><QA/></ProtectedRoute>}/>
                                        <Route key={index} path={route.path} element={<ProtectedRoute role={route.role}>{route.component}</ProtectedRoute>}/>
                                    </Route>

                                    {/*lich su cau hoi*/}
                                    <Route key="q-a-history" path="/api/app/q-a-history" element={<Home/>}>
                                        <Route index element={<ProtectedRoute role={'user'}><HistoryQA/></ProtectedRoute>}></Route>
                                        <Route key={index} path={route.path} element={<ProtectedRoute role={route.role}>{route.component}</ProtectedRoute>}/>
                                    </Route>

                                    {/*chi tiet hoi thoai*/}
                                    <Route key="q-a-detail" path="/api/app/q-a-detail/:id" element={<Home/>}>
                                        <Route index element={<ProtectedRoute role={'user'}><DetailQA/></ProtectedRoute>}></Route>
                                        <Route key={index} path={route.path} element={<ProtectedRoute role={route.role}>{route.component}</ProtectedRoute>}/>
                                    </Route>

                                    {/*trang cá nhân*/}
                                    <Route key="profile" path="/api/app/profile" element={<Home/>}>
                                        <Route index element={<ProtectedRoute role={'user'}><Profile/></ProtectedRoute>}></Route>
                                        <Route key={index} path={route.path} element={<ProtectedRoute role={route.role}>{route.component}</ProtectedRoute>}/>
                                    </Route>
                                    {/* dang ky gia han */}
                                    <Route key="register-extend" path="/api/app/register-extend" element={<Home/>}>
                                        <Route index element={<ProtectedRoute role={'user'}><RegisterExtend/></ProtectedRoute>}></Route>
                                        <Route key={index} path={route.path} element={<ProtectedRoute role={route.role}>{route.component}</ProtectedRoute>}/>
                                    </Route>

                                   

                                    {/* danh sach dang ky gia hang */}
                                    <Route key="register-list" path="/api/app/register-list" element={<Home/>}>
                                        <Route index element={<ProtectedRoute role={'user'}><RegisterList/></ProtectedRoute>}></Route>
                                        <Route key={index} path={route.path} element={<ProtectedRoute role={route.role}>{route.component}</ProtectedRoute>}/>
                                    </Route>

                                    <Route key="cashback" path="cashback" element={<Home/>}>
                                        <Route index element={<ProtectedRoute role={'user'}><Cashback/></ProtectedRoute>}></Route>
                                        <Route key={index} path={route.path} element={<ProtectedRoute role={route.role}>{route.component}</ProtectedRoute>}/>
                                    </Route>
                                    <Route key="doc" path="doc" element={<Home/>}>
                                        <Route index element={<ProtectedRoute role={'user'}><Doc /></ProtectedRoute>}></Route>
                                        <Route key={index} path={route.path} element={<ProtectedRoute role={route.role}>{route.component}</ProtectedRoute>}/>
                                    </Route>
                                    <Route key="sale" path="sale" element={<Home/>}>
                                        <Route index element={<ProtectedRoute role={'user'}><Sale /></ProtectedRoute>}></Route>
                                        <Route key={index} path={route.path} element={<ProtectedRoute role={route.role}>{route.component}</ProtectedRoute>}/>
                                    </Route>
                                    
                                </>
                            )
                        }
                    })}
                    <Route path='*' element={<PageNotFound/>}/>
                </Routes>
            </Suspense>
        </Router>
    )
}
export default Routing;

