import {createAsyncThunk} from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import {ACCESS_TOKEN_KEY} from "../config/token";
import {_convertObjectToQuery} from "./winer-service";
import axiosInstance from "../config/customAxios";

const token = Cookies.get(ACCESS_TOKEN_KEY)
export const cashbackAPI = createAsyncThunk(
    'cashback/register',
    async (data) => {
         
        var form_data = new FormData()
        form_data.append('CodeLogin', data['CodeLogin']);
        form_data.append('AttachDocuments', data['AttachDocuments']);
        form_data.append('WinnerId', data['WinnerId']);
        form_data.append('claimType', data['claimType']);
        
         if(data['Files'][0]){
            form_data.append('Files', data['Files'][0]);
        }
        if(data['Files'][1]){
            form_data.append('Files', data['Files'][1]);
        }
        if(data['Files'][2]){
         form_data.append('Files', data['Files'][2]);
        }
        if(data['Files'][3]){
            form_data.append('Files', data['Files'][3]);
        }
        if(data['Files'][4]){
            form_data.append('Files', data['Files'][4]);
        }
  
        const res = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/api/app/cashback-claim`, form_data, 
            {
                headers: {
                "Content-Type": "multipart/form-data"
                }
            }
        )
        return res.data
   }
)
export const cashbackListAPI = createAsyncThunk(
    'cashback/list',
    async (claimType) => {
        console.log(claimType)       
        const res = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/api/app/cashback-claim/my-claim?claimType=${claimType.claimType}`)
        return res.data
   }

)
export const cashbackCancelAPI = createAsyncThunk(
    'cashback/cancel',
    async (claimId) => {
 
        const res = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/api/app/cashback-claim/winner-cancel`,{
            claimId: claimId.claimId
        })
        return res.data
   }

)
 