import { lazy } from "react";
const NewsPage = lazy(() => import("../pages/news/news"));
const CatPage = lazy(() => import("../pages/news/cat"));
const DetailPage = lazy(() => import("../pages/news/detail"));
const LoginPage = lazy(() => import("../pages/login/login"));
const WinerPage = lazy(() => import("../pages/auction-history/auction-history") );
const QAPage = lazy(() => import("../pages/q-a/q-a"));
const RegisterExtend = lazy(() => import("../pages/register-extend"));
const DocPage = lazy(() => import("../pages/doc/doc"));
const CashbackPage = lazy(() => import("../pages/cashback"));
const SalePage = lazy(() => import("../pages/sale"));

const ROUTES = [
  {
    path: "",
    component: <NewsPage />,
  },
  {
    path: "danh-muc/:id",
    component: <CatPage />,
  },
  {
    path: "tin-tuc/:id",
    component: <DetailPage />,
  },
  {
    path: "login",
    component: <LoginPage />,
  },
  {
    path: "winer",
    component: <WinerPage />,
    role: ["user"],
  },
  {
    path: "doc",
    component: <DocPage />,
    role: ["user"],
  },
  {
    path: "cashback",
    component: <CashbackPage />,
    role: ["user"],
  },
  {
    path: "sale",
    component: <SalePage />,
    role: ["user"],
  },
  {
    path: "q-a",
    component: <QAPage />,
    role: ["user"],
  },
  {
    path: "register-extend",
    component: <RegisterExtend />,
    role: ["user"],
  },
  {
    path: "register-list",
    component: <RegisterExtend />,
    role: ["user"],
  },
];
export default ROUTES;
