const LOGIN_BY_WCODE_LIMIT_TIME = 5;
const INTERVAL_TIME_TO_RETRY = 10 * 60 * 1000; // 10mins
const KEY_PREFIX = "appls:login_by_wcode_count:";
/**
 * @type {NodeJS.Timer | undefined}
 */
let timer;
function setIntervalToClearCounter() {
  if (!!timer) return;
  timer = setInterval(() => {
    removeAllCounter();
    clearInterval(timer);
    timer = undefined;
  }, INTERVAL_TIME_TO_RETRY);
}
/**
 * @param {string} value
 */
function tryParseInt(value) {
  if (!value) return 0;
  if (!/^[0-9]+$/.test(value)) return 0;
  return +value;
}
/**
 * @param {string} code
 */
export function getLoginCountFromLocalStorage(code) {
  if (!code) return 0;
  const value = window.localStorage.getItem(`${KEY_PREFIX}${code}`);
  return tryParseInt(value);
}
/**
 * @param {string} code
 */
export function saveLoginCountToLocalStorage(code) {
  if (!code) return;
  const previousValue = getLoginCountFromLocalStorage(code);
  return window.localStorage.setItem(`${KEY_PREFIX}${code}`, previousValue + 1);
}
/**
 * @param {string} code
 */
export function hasExceededTimeLimitOfLogin(code) {
  const count = getLoginCountFromLocalStorage(code);
  if (count >= LOGIN_BY_WCODE_LIMIT_TIME) {
    setIntervalToClearCounter();
    return true;
  }
  return false;
}
/**
 * @returns {{ [x: string]: number }}
 */
export function getAllLoginCounter() {
  const keys = Object.keys(window.localStorage).filter((_) =>
    _.startsWith(KEY_PREFIX)
  );
  if (!keys || keys.length === 0) return null;
  return keys.reduce(
    (counters, key) => ({
      ...counters,
      [key]: tryParseInt(window.localStorage.getItem(key)),
    }),
    {}
  );
}
function removeAllCounter() {
  const keys = Object.keys(window.localStorage).filter((_) =>
    _.startsWith(KEY_PREFIX)
  );
  if (!keys || keys.length === 0) return null;
  keys.forEach((key) => {
    window.localStorage.removeItem(key);
  }, {});
}
/**
 * keep all counter after use `localStorage.clear()`;
 * @param {{ [x: string]: number}} counters
 */
export function keepAllLoginCounter(counters) {
  if (!counters) return;
  if (Object.keys(counters).length === 0) return;
  Object.keys(counters).forEach((key) => {
    const value = tryParseInt(counters[key]);
    window.localStorage.setItem(key, value);
  });
}
