import "../../custom.scss"
import {useEffect, useState} from "react";
import {createQA, detailQA} from "../../service/q-a-service";
import {useDispatch, useSelector} from "react-redux";
import { useNavigate, useParams} from "react-router-dom";
import Modal from 'react-modal';
import {Field, Form, Formik} from "formik";
import Input from "../../components/core/form/input/input";
import TextArea from "../../components/core/form/text-area/text-area";
import * as Yup from "yup";
import Button from "../../components/core/button/button-default/button";
import {toast} from "react-toastify";
import {MESSAGE_SUCCESS} from "../../config/message";
import {toastCss} from "../../config/toasterConfig";
import './style.css';
import Sidebar from "../../components/Sidebar";
import { useLocation } from "react-router-dom";
 
const DetailQA = () => {
    const [pageCurrent, setPageCurrent] = useState('');
    const location = useLocation();
    useEffect(() => {
        let lastPart = location.pathname.split("/").pop();;
        setPageCurrent(lastPart);
    }, [location.pathname]);


    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [lastQuestionId, setLastQuestionId] = useState('');

    let {id} = useParams()
    const InputSchema = Yup.object().shape({
        description: Yup.string()
            .required("Vui lòng nhập các thông tin cần hỗ trợ!"),
    })

    const questions = useSelector(state => {
        return state.qA.items
    })

    useEffect(() => {
        dispatch(detailQA(id));
    }, []);

    useEffect(() => {
        if (questions.length > 0) {
            console.log(questions[questions.length-1]);

            setLastQuestionId(questions[questions.length-1].id);
        }
    }, [questions]);

    const handleSubmit = async (value) => {
        const dataBody = convertDataSubmit(value);
        dispatch(createQA(dataBody)).then((res) => {

            toast.success(MESSAGE_SUCCESS.Q_A_SUCCESS, toastCss)
            dispatch(detailQA(id));
        }).catch((err) => {
            console.log(err)
        });
    };

    const convertDataSubmit = (value) => {
        return {
            parentId: value?.parentId || lastQuestionId,
            description: value.description
        }
    }

    return (
        <>
         
            <div>
                <div className="container">
                
                <div className="row mt-5">
                           
                            <div className="col-md-3">
                                <div className="profile-left">
                                    <Sidebar pageCurrent={pageCurrent}/>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="profile-right border rounded p-2 ">
                                    <div className="row">
                                        <div className="col-md-12">
                                            {/* Page title */}
                                            <div className="row">
                                              
                                               
                                                <div className="col-md-12 text-start">
                                                   
                                                    <h1 className="heading-28-bold neutral-100 mt-3">Chủ đề: {questions[0]?.title}</h1>
                                                    <hr className="mb-3" />
                                                </div>
                                                   
                                            </div>

                                            {/* Table */}
                                            <div>
                                                <div className="mt-lg-4  rounded border">
                                                    <div className="d-flex flex-column chatbox p-2 bg-light" style={{marginBottom:"10px"}}>
                                                        {questions?.map((item) => {
                                                            return <div style={{marginBottom:"3px"}}>
                                                                <div className={item.isAdmin ==  true ?  'boxchat boxAdmin' : 'boxchat boxCustomer'}>
                                                                    <div className=" neutral-100">{item.isAdmin ==  true ? 'Tư vấn viên' : 'Bạn'}: {item?.description}</div>
                                                                </div>

                                                            </div>
                                                        })}
                                                    </div>
                                                    <Button onClick={() => setModalIsOpen(true)} text="Phản hồi"/>
                                                    <Modal  isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)}>
                                                        <div className={`d-flex justify-content-between`}>
                                                        <h2>Phản hồi</h2>
                                                        </div>
                                                        <Formik
                                                            initialValues={{ description: '', parentId: ''}}
                                                            validationSchema={InputSchema}
                                                            onSubmit={(values, {resetForm}) => {
                                                                handleSubmit(values)
                                                                resetForm()
                                                                setModalIsOpen(false)
                                                            }}
                                                        >
                                                            <Form>
                                                                <div className="mt-lg-4" style={{padding: '0 10px'}}>
                                                                    <Field as={Input} name="parentId" type={'hidden'} className="mt-5"
                                                                        id={lastQuestionId}/>
                                                                    {/* <Field as={Input} name="title" type={'text'} className="mt-5"
                                                                            placeholder="Nhập tiêu đề cần hỗ trợ"/> */}
                                                                    <Field as={TextArea} name="description" type={'text'} className="mt-5"
                                                                        placeholder="Mô tả chi tiết câu hỏi cần hỗ trợ"/>
                                                                    <div className="row mt-4 mb-1 justify-content-end">
                                                                            <Button
                                                                                className="submit"
                                                                                text="Gửi"
                                                                                type="submit"
                                                                            />
                                                                    </div>
                                                                </div>
                                                            </Form>
                                                        </Formik>
                                                    </Modal>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </>
    )
}
export default DetailQA