import React from 'react';
import { Link } from 'react-router-dom';

const PageNotFound = () => {
  return (
    <div className="page-not-found">
      <img
        className='bg-page'
        src="https://www.pngitem.com/pimgs/m/561-5616833_image-not-found-png-not-found-404-png.png"
        alt="not-found"
      />
      <Link to="/api/app/winer" className="btn-home">
        Trở về trang chủ
      </Link>
    </div>
  );
};

export default PageNotFound;
