import React from "react";

const Label = (props) => {
  return (
    <>
      <span className="label pb-1">{props.text}</span>
    </>
  );
};

export default Label;
