import {createAsyncThunk} from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import {ACCESS_TOKEN_KEY} from "../config/token";
import {_convertObjectToQuery} from "./winer-service";
import axiosInstance from "../config/customAxios";

const token = Cookies.get(ACCESS_TOKEN_KEY)

export const showListRegisterList = createAsyncThunk(
    '/auction-c08/extend-customer',
    async (body) => {
         let res = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/api/app/auction-c08/extend-customer?${_convertObjectToQuery(body)}&sortType=DESCEND`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return res.data
    }
)

export const getExtendMessage = createAsyncThunk(
    '/q-aApp-extend/async-by-user',
    async (body) => {
         let res = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/api/app/q-aApp-extend/async-by-user?extendIds=${body}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return res.data
    }
)

export const downloadFile = createAsyncThunk(
    '/auction-c08/blob-download',
    async (urlFile) => {
        let res = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/api/app/auction-c08/blob-download?link=${urlFile}`, {
            responseType: 'blob',
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return res.data
    }
)
