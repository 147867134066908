import {createSlice} from "@reduxjs/toolkit";
import {createQA, detailQA, showListQA} from "../../service/q-a-service";

const initialState = {
    items: [],
    totalCount: 0
}

const qASlice = createSlice({
    name: 'q-a',
    initialState,
    reducers:{
        setItems: (state, action) => {
            state.items = [...state.items, action.payload];
        },
    },
    extraReducers: builder => {
        builder.addCase(showListQA.fulfilled, (state, action) => {
            state.items = [...action.payload.items];
            state.totalCount = action.payload.totalCount;
        })
        builder.addCase(createQA.fulfilled, (state, action) => {
            state.items = [...state.items, action.payload]
        })
        builder.addCase(detailQA.fulfilled,(state, action)=>{
            state.items = [...action.payload];
        })
    }
})

export const { setItems } = qASlice.actions;
export default qASlice.reducer


