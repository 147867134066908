import React from "react";
import Label from "../label/label";
import "./scss/input.scss";

const Input = (props) => {
  return (
    <>
      <div className="d-flex flex-column w-100">
        <Label text={props.label} />
        <input
          className="input"
          type={props.type}
          value={props.value}
          disabled={props.disabled}
          placeholder={props.placeholder}
          id={props.id}
          name={props.name}
          onChange={props.onChange}
        />
      </div>
    </>
  );
};

export default Input