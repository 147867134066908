import "../../custom.scss";
import { useEffect, useState } from "react";
import {
  showListRegisterList,
  downloadFile,
} from "../../service/register-list";
import { useDispatch, useSelector } from "react-redux";
import Pagnigation from "../../components/core/pagnigation/pagnigation";
import Sidebar from "../../components/Sidebar";
import { useLocation } from "react-router-dom";
import MessageModal from "./components/modal";

const RegisterList = () => {
  const [pageCurrent, setPageCurrent] = useState("");
  const [isShowModal, setIsShowModal] = useState(false);
  const [data, setData] = useState(null);
  const location = useLocation();
  useEffect(() => {
    let lastPart = location.pathname.split("/").pop();
    setPageCurrent(lastPart);
  }, [location.pathname]);

  const dispatch = useDispatch();
  const registerData = useSelector((state) => {
    return state.register.items;
  });

  const PAGESIZE = {
    COMMON: 10,
  };

  let initFilter = {
    MaxResultCount: PAGESIZE.COMMON,
    SkipCount: 0,
  };
  const [totalCount, setTotalCount] = useState(0);
  const [filter, setFilter] = useState(initFilter);
  useEffect(() => {
    dispatch(showListRegisterList())
      .then((res) => {
        let { totalCount } = res.payload;
        setTotalCount(totalCount);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handlePageChange = (page) => {
    let newFilter = { ...filter };
    newFilter.SkipCount = page * 10;
    setFilter(newFilter);
    dispatch(showListRegisterList(newFilter));
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    let newFilter = { ...filter };
    newFilter.SkipCount = page * 10;
    newFilter.MaxResultCount = newPerPage;
    setFilter(newFilter);
    dispatch(showListRegisterList(newFilter));
  };

  const handleDownload = (item) => {
    onDownload(item);
  };
  const onDownload = async (item) => {
    try {
      dispatch(downloadFile(item.urlFile)).then((res) => {
        const urlFile = item.urlFile.split("/").pop();
        const blob = new Blob([res.payload], { type: "application/".ext });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;

        a.download = "download_" + urlFile;
        a.click();
        return window.URL.revokeObjectURL(url);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const paginate = (pageNumber) => setFilter(pageNumber);

  const showModal = (item) => {
    setData(item);
    setIsShowModal(true);
  };

  const onCloseModal = () => {
    setIsShowModal(false);
  }

  return (
    <>
      <div>
        <div className="container">
          {/* Page title */}

          <div className="row mt-5">
            <div className="col-md-3">
              <div className="profile-left">
                <Sidebar pageCurrent={pageCurrent} />
              </div>
            </div>
            <div className="col-md-9">
              <div className="profile-right border rounded p-2 bg-light">
                <div className="row">
                  <div className="col-md-12">
                    <div>
                      <h1 className="heading-28-bold neutral-100 text-center">
                        Danh sách đề nghị gia hạn
                      </h1>
                    </div>
                    <hr className="mb-3" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    {/* Table */}
                    <div>
                      <div className="">
                        <table className="table ">
                          <thead className="bg-neutral-10">
                            <tr>
                              <th
                                scope="col"
                                className="body-16-medium neutral-60"
                              >
                                Họ tên
                              </th>
                              <th
                                scope="col"
                                className="body-16-medium neutral-60 text-center"
                              >
                                Số điện thoại
                              </th>
                              <th
                                scope="col"
                                className="body-16-medium neutral-60 text-center"
                              >
                                Biển số
                              </th>
                              <th
                                scope="col"
                                className="body-16-medium neutral-60 text-center"
                              >
                                Đơn gia hạn
                              </th>
                              <th
                                scope="col"
                                className="body-16-medium neutral-60 text-center"
                              >
                                Trạng thái duyệt
                              </th>
                              <th
                                scope="col"
                                className="body-16-medium neutral-60 text-center"
                              >
                                Phản hồi
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {registerData?.map((item) => (
                              <tr>
                                <td className="body-16-semibold neutral-100">
                                  {item.fullName}
                                </td>
                                <td className="body-16-semibold neutral-100 text-center">
                                  {item.phoneNumber}
                                </td>
                                <td className="semantic-success-1 text-center">
                                  {item.noPlaceName}
                                </td>
                                <td className="semantic-success-1 text-center">
                                  <div>
                                    <div
                                      onClick={() => handleDownload(item)}
                                      style={{ cursor: "pointer" }}
                                      className={`status-label rounded px-3 user-active`}
                                    >
                                      Tải đơn
                                    </div>
                                    {/* <a href={`https://be-bienso-staging.phanmemhanoi.com/api/app/auction-c08/blob-download?link=${cell.urlFile}`} target='_blank'>Tải file</a> */}
                                  </div>
                                </td>
                                <td className="text-center">
                                  {item.status === 0 ? (
                                    <span className="text-dart">
                                      Chưa duyệt
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {item.status === 1 ? (
                                    <span className="text-danger">
                                      Không duyệt
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {item.status === 2 ? (
                                    <span className="text-success">
                                      Đã duyệt
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </td>
                                <td>
                                  <strong
                                    style={{ cursor: "pointer" }}
                                    onClick={() => showModal(item)}
                                  >
                                    Xem phản hồi
                                  </strong>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <Pagnigation
                          postsPerPage={PAGESIZE}
                          totalPosts={totalCount}
                          paginate={paginate}
                          handlePageChange={handlePageChange}
                          handlePerRowsChange={handlePerRowsChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MessageModal isShowModal={isShowModal} data={data} onCloseModal={onCloseModal} />
    </>
  );
};
export default RegisterList;
