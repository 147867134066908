import {createSlice} from "@reduxjs/toolkit";
import {docAPI} from "../../service/doc";

const initialState = {
    items: [],
    totalCount: 0,
 }

const docSlice = createSlice({
    name: 'back',
    initialState,
    reducers:{
        setItems: (state, action) => {
            state.items = [...state.items, action.payload];
        },  
    },
    extraReducers: builder => {
        builder.addCase(docAPI.fulfilled, (state, action) => {
            state.items = [...action.payload.items];
            state.totalCount = action.payload.totalCount;
        })
     
       
    }
})

export const { setItems } = docSlice.actions;
export default docSlice.reducer


