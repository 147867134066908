import {createSlice} from "@reduxjs/toolkit";
import {detailNews, showListNews} from "../../service/news-service";

const initialState = {
    items: [],
    totalCount: 0
}

const NewsSlice = createSlice({
    name: 'news',
    initialState,
    reducers:{
        setItems: (state, action) => {
            state.items = [...state.items, action.payload];
        },
    },
    extraReducers: builder => {
        builder.addCase(showListNews.fulfilled, (state, action) => {
            state.items = [...action.payload.items];
            state.totalCount = action.payload.totalCount;
        })
      
        builder.addCase(detailNews.fulfilled,(state, action)=>{
             state.items = [action.payload];
        })
    }
})

export const { setItems } = NewsSlice.actions;
export default NewsSlice.reducer


